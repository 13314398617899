<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    >
<!--      <base-img-->
<!--        :src="require('@/assets/logo.svg')"-->
<!--        class="mr-3 hidden-xs-only"-->
<!--        contain-->
<!--        max-width="52"-->
<!--        width="100%"-->
<!--      />-->

      <base-img
        :src="require('@/assets/logo_header.png')"
        contain
        max-width="128"
        width="100%"
      />

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="data in items"
            :key="data.key"
            :to="data.url"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ data.text }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        {
          key: 'Home',
          url: '/',
          text: 'Accueil',
        },
        {
          key: 'Enterprise',
          url: '/notre-entreprise',
          text: 'Notre Entreprise',
        },
        {
          key: 'Gallery',
          url: '/galerie',
          text: 'Galerie',
        },
        {
          key: 'Contact',
          url: '/contact',
          text: 'Contact',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
